import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./ActiveTimeBattleCard.css";

const widthString = (value) => {
	return value * 100 + "%";
}

const ActiveTimeBattleCard = (props) => {
	const data = props.characterData;
	const placeholderProgress = data.currentPoints / data.maxPoints;
	const backgroundColor = data.currentPoints < data.maxPoints ? "#555" : "green";
	return (
		<div className="ActiveTimeBattleCard" style={{backgroundColor: backgroundColor}}>
			<div className="infoLine">
				<span><input type="text" placeholder="Character name" /></span>
				<span>Initiative: {data.initiative}</span>
				<span>Turns: {data.turns}</span>
			</div>
			<div className="progressBar">
				<div className="progressBarFill" style={{ width: widthString(placeholderProgress) }} />
			</div>
		</div>
	);
}

export default ActiveTimeBattleCard;
