import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./ToolSelector.css";

const ToolSelector = () => {

	const Tool = (props) => {
		return <span className="tool" >
			<Link to={"/tabletop-tools/" + props.urlSuffix}>
				<span className="toolContents" >
					{props.name}
				</span>
			</Link>
		</span>
	};

	return (
		<div className="ToolSelector">
			<div className="toolList" >
				<Tool urlSuffix="" name="Home" />
				<Tool urlSuffix="active-time-battle" name="Active Time Battle Tracker" />
			</div>
			<div className="divider" />
		</div>
	);
}

export default ToolSelector;
